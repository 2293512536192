import actionCreator from "../action";
import { ContactServices } from "services";

// ================ Action types ================ //
export const LOAD_CONTACTS_REQUEST = "app/Contact/LOAD_CONTACTS_REQUEST";
export const LOAD_CONTACTS_SUCCESS = "app/Contact/LOAD_CONTACTS_SUCCESS";
export const LOAD_CONTACTS_ERROR = "app/Contact/LOAD_CONTACTS_ERROR";

export const UPDATE = "app/Contact/UPDATE";
export const UPDATE_SUCCESS = "app/Contact/UPDATE_SUCCESS";
export const UPDATE_ERROR = "app/Contact/UPDATE_ERROR";

export const DELETE = "app/Contact/DELETE";
export const DELETE_SUCCESS = "app/Contact/DELETE_SUCCESS";
export const DELETE_ERROR = "app/Contact/DELETE_ERROR";

// ================ Reducer ================ //

const initialState = {
  contacts: [],
  meta: {},
  searchParams: { page: 1 },
  isContactLoading: false,
  contactError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;
  switch (type) {
    case LOAD_CONTACTS_ERROR:
      return {
        ...state,
        isContactLoading: false,
        contactError: payload,
      };

    case LOAD_CONTACTS_REQUEST:
      return {
        ...state,
        isContactLoading: true,
        contactError: null,
      };

    case LOAD_CONTACTS_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;
      return {
        ...state,
        isContactLoading: false,
        contacts: data,
        meta: { ...rest },
        searchParams: { page },
      };

    case UPDATE: {
      return {
        ...state,
        contactError: null,
        isContactLoading: true,
      };
    }
    case UPDATE_SUCCESS:
      return {
        ...state,
        isContactLoading: false,
      };
    case UPDATE_ERROR: {
      return {
        ...state,
        isContactLoading: false,
        contactError: payload,
      };
    }

    case DELETE: {
      return {
        ...state,
        isContactLoading: true,
        contactError: null,
      };
    }
    case DELETE_SUCCESS:
      return {
        ...state,
        isContactLoading: false,
      };
    case DELETE_ERROR: {
      return {
        ...state,
        isContactLoading: false,
        contactError: payload,
      };
    }
    default:
      return state;
  }
}
// ================ Action creators ================ //
export const loadInitDataRequest = () => ({
  type: LOAD_CONTACTS_REQUEST,
});

export const loadInitDataSuccess = (payload) => ({
  type: LOAD_CONTACTS_SUCCESS,
  payload,
});

export const loadInitDataError = (error) => ({
  type: LOAD_CONTACTS_ERROR,
  payload: error,
});

export const updateStatusRequest = () => ({
  type: UPDATE,
});

export const updateStatusSuccess = () => ({
  type: UPDATE_SUCCESS,
});

export const contactError = (error) => ({
  type: UPDATE_ERROR,
  payload: error,
});
// Thunks

export const loadInitData = (params) => async (dispatch, getState) => {
  dispatch(loadInitDataRequest());

  try {
    const contacts = await ContactServices.getAll(params);
    dispatch(loadInitDataSuccess(contacts));
  } catch (err) {
    dispatch(loadInitDataError(err));
  }
};

export const updateContactStatus =
  (id, status) => async (dispatch, getState) => {
    const searchParams = getState().contact.searchParams;
    dispatch(updateStatusRequest());
    try {
      await ContactServices.update(id, {
        status: status,
      });
      dispatch(updateStatusSuccess());
      dispatch(loadInitData(searchParams));
    } catch (error) {
      dispatch(contactError(error.message));
    }
  };

export const deleteContact = (id) => async (dispatch, getState) => {
  dispatch(actionCreator(DELETE));
  const searchParams = getState().contact.searchParams;
  try {
    await ContactServices.delete(id);
    dispatch(actionCreator(DELETE_SUCCESS));
    dispatch(loadInitData(searchParams));
  } catch (error) {
    dispatch(actionCreator(DELETE_ERROR, error.message));
  }
};
