import {
  DashboardOutlined,
  ReadOutlined,
  EditOutlined,
  UserOutlined,
  PhoneOutlined,
  ProfileOutlined,
  FileImageOutlined,
  CommentOutlined,
  InsertRowRightOutlined,
  CalendarOutlined,
  BookOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, APP_NAME } from "configs/AppConfig";
const cmsNavTree = [
  {
    key: "cms",
    path: `${APP_PREFIX_PATH}`,
    title: APP_NAME,
    icon: "",
    breadcrumb: true,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "Tổng quan",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "registrations",
        path: `${APP_PREFIX_PATH}/registrations`,
        title: "Đăng kí",
        icon: EditOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "contact-us",
        path: `${APP_PREFIX_PATH}/contacts`,
        title: "Liên hệ",
        icon: PhoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "test-results",
        path: `${APP_PREFIX_PATH}/test-results`,
        title: "Bài test",
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "category",
        path: `${APP_PREFIX_PATH}/categories`,
        title: "Danh mục khoá học",
        icon: InsertRowRightOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "courses",
        path: `${APP_PREFIX_PATH}/courses`,
        title: "Khoá học",
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "teachers",
        path: `${APP_PREFIX_PATH}/teachers`,
        title: "Giáo viên",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "testimonials",
        path: `${APP_PREFIX_PATH}/testimonials`,
        title: "Cảm nhận học viên",
        icon: CommentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "news",
        path: `${APP_PREFIX_PATH}/news`,
        title: "Tin tức",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "banners",
        path: `${APP_PREFIX_PATH}/banners`,
        title: "Banners",
        icon: FileImageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "activities",
        path: `${APP_PREFIX_PATH}/activities`,
        title: "Video hoạt động",
        icon: VideoCameraAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
const navigationConfig = [...cmsNavTree];

export default navigationConfig;
