import { IntroServices } from "services";
import actionCreator from "redux/action";
import { LOAD_CONTACTS_ERROR } from "./Contact.duck";

const UPDATE = "app/intro/UPDATE";
const UPDATE_SUCCESS = "app/intro/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/intro/UPDATE_ERROR";

const LOAD_INIT_DATA = "app/intro/LOAD_INIT_DATA";
const LOAD_INIT_DATA_SUCCESS = "app/intro/LOAD_INIT_DATA_SUCCESS";
const LOAD_INIT_DATA_ERROR = "app/intro/LOAD_INIT_DATA_ERROR";

const initialState = {
  intro: [],
  meta: {},
  isIntroLoading: false,
  introError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_INIT_DATA:
      return {
        ...state,
        isIntroLoading: true,
        introError: null,
      };
    case LOAD_INIT_DATA_SUCCESS:
      const { data, ...rest } = payload;
      return {
        ...state,
        isIntroLoading: false,
        intro: data,
        meta: { ...rest },
      };
    case LOAD_CONTACTS_ERROR:
      return {
        ...state,
        isIntroLoading: false,
        introError: payload,
      };
    case UPDATE:
      return {
        ...state,
        isIntroLoading: true,
        introError: null,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isIntroLoading: false,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        isIntroLoading: false,
        introError: payload,
      };
    default:
      return state;
  }
}

// ================ Thunks ================ //
export const loadInitData = (params) => async (dispatch, state) => {
  dispatch(actionCreator(LOAD_INIT_DATA));
  try {
    const intro = await IntroServices.getAll(params);
    dispatch(actionCreator(LOAD_INIT_DATA_SUCCESS, intro));
  } catch (error) {
    dispatch(actionCreator(LOAD_INIT_DATA_ERROR, error.message));
  }
};

export const updateIntro = (id, updateRecord) => async (dispatch, getState) => {
  dispatch(actionCreator(UPDATE));
  try {
    await IntroServices.update(id, updateRecord);
    dispatch(actionCreator(UPDATE_SUCCESS));
    dispatch(loadInitData());
  } catch (error) {
    dispatch(actionCreator(UPDATE_ERROR, error.message));
  }
};
