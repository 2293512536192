import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { loadInitData } from "redux/ducks/Dashboard.duck";
import { connect } from "react-redux";
export const AppViews = (props) => {
  const { onLoadInitData } = props;

  useEffect(() => {
    // onLoadInitData();
  }, []);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/registrations`}
          component={lazy(() => import(`./registrations`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/contacts`}
          component={lazy(() => import(`./contact-us`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/categories`}
          component={lazy(() => import(`./categories`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/courses`}
          component={lazy(() => import(`./courses`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/teachers`}
          component={lazy(() => import(`./teachers`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/testimonials`}
          component={lazy(() => import(`./feedbacks`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/banners`}
          component={lazy(() => import(`./banners`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/news`}
          component={lazy(() => import(`./news`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/activities`}
          component={lazy(() => import(`./activities`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/test-results`}
          component={lazy(() => import(`./test-results`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLoadInitData: (params) => dispatch(loadInitData(params)),
  };
};
export default connect(null, mapDispatchToProps)(AppViews);
