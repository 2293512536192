import JwtAuthService from "services/JwtAuthService";

// ================ Action types ================ //
export const GET_USER_INFO_REQUEST = "app/User/GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "app/User/GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_ERROR = "app/User/GET_USER_INFO_ERROR";
// ================ Reducer ================ //

const initialState = {
  user: {},
  loadUserInProgress: false,
  loadUserError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        user: {},
        loadUserInProgress: true,
        loadUserError: null,
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loadUserInProgress: false,
      };
    case GET_USER_INFO_ERROR:
      return {
        ...state,
        loadUserError: action.payload,
        loadUserInProgress: false,
      };
    default:
      return state;
  }
}
// ================ Action creators ================ //
export const getUserInfoRequest = () => ({
  type: GET_USER_INFO_REQUEST,
});

export const getUserInfoSuccess = (user) => ({
  type: GET_USER_INFO_SUCCESS,
  payload: user,
});

export const getUserInfoError = (error) => ({
  type: GET_USER_INFO_ERROR,
  payload: error,
});

// Thunks
export const updateUserInfo = () => async (dispatch, getState) => {
  const { isAuthenticated } = getState().auth;
  if (isAuthenticated) {
    dispatch(getUserInfoRequest());
    try {
      const user = await JwtAuthService.getUserInfoFromToken();
      dispatch(getUserInfoSuccess(user));
    } catch (error) {
      dispatch(getUserInfoError(error));
    }
  }
};
