import actionCreator from "redux/action";
import { CategoryServices, CourseServices } from "services";

// ================ Action types ================ //
const LOAD_INIT_DATA = "app/course/LOAD_INIT_DATA";
const LOAD_INIT_DATA_SUCCESS = "app/course/LOAD_INIT_DATA_SUCCESS";
const LOAD_INIT_DATA_ERROR = "app/course/LOAD_INIT_DATA_ERROR";

const ADD = "app/course/ADD";
const ADD_SUCCESS = "app/course/ADD_SUCCESS";
const ADD_ERROR = "app/course/ADD_ERROR";

const UPDATE = "app/course/UPDATE";
const UPDATE_SUCCESS = "app/course/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/course/UPDATE_ERROR";

const DELETE = "app/course/DELETE";
const DELETE_SUCCESS = "app/course/DELETE_SUCCESS";
const DELETE_ERROR = "app/course/DELETE_ERROR";

const GET = "app/course/GET";
const GET_SUCCESS = "app/course/GET_SUCCESS";
const GET_ERROR = "app/course/GET_ERROR";

const FETCH_CATEGORIES = "app/course/FETCH_CATEGORIES";
// ================ Reducer ================ //

const initialState = {
  courses: [],
  meta: {},
  searchParams: { page: 1 },
  isCourseLoading: false,
  courseError: null,
  categories: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_INIT_DATA:
      return {
        ...state,
        isCourseLoading: true,
        courseError: null,
      };
    case LOAD_INIT_DATA_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;

      return {
        ...state,
        isCourseLoading: false,
        courses: data,
        meta: { ...rest },
        searchParams: { page },
      };
    case LOAD_INIT_DATA_ERROR:
      return {
        ...state,
        isCourseLoading: false,
        courseError: payload,
      };
    case ADD:
      return {
        ...state,
        isCourseLoading: true,
        courseError: null,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        isCourseLoading: false,
      };
    case ADD_ERROR:
      return {
        ...state,
        isCourseLoading: false,
        courseError: payload,
      };
    case DELETE:
      return {
        ...state,
        isCourseLoading: true,
        courseError: null,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        isCourseLoading: false,
      };
    case DELETE_ERROR:
      return {
        ...state,
        isCourseLoading: false,
        courseError: payload,
      };

    case UPDATE:
      return {
        ...state,
        isCourseLoading: true,
        courseError: null,
      };
    case UPDATE_SUCCESS: {
      return {
        ...state,
        isCourseLoading: false,
      };
    }

    case UPDATE_ERROR:
      return {
        ...state,
        isCourseLoading: false,
        courseError: payload,
      };
    case FETCH_CATEGORIES: {
      return {
        ...state,
        categories: payload.data,
      };
    }
    default:
      return state;
  }
}
// ================ Action creators ================ //

// ================ Thunks ================ //
export const loadInitData = (params) => async (dispatch, state) => {
  dispatch(actionCreator(LOAD_INIT_DATA));
  try {
    const courses = await CourseServices.getAll(params);
    dispatch(actionCreator(LOAD_INIT_DATA_SUCCESS, courses));
  } catch (error) {
    dispatch(actionCreator(LOAD_INIT_DATA_ERROR, error.message));
  }
};

export const fetchCategories = (params) => async (dispatch, state) => {
  try {
    const categories = await CategoryServices.getAll(params);
    dispatch(actionCreator(FETCH_CATEGORIES, categories));
  } catch (error) {}
};

export const addNewCourse = (course) => async (dispatch, state) => {
  dispatch(actionCreator(ADD));
  try {
    const res = await CourseServices.add(course);
    dispatch(actionCreator(ADD_SUCCESS));
    dispatch(loadInitData());
    return res;
  } catch (error) {
    dispatch(actionCreator(ADD_SUCCESS, error.message));
  }
};

export const updateCourse = (id, updatedInfo) => async (dispatch, getState) => {
  const searchParams = getState().course.searchParams;

  dispatch(actionCreator(UPDATE));
  try {
    const res = await CourseServices.update(id, updatedInfo);
    dispatch(actionCreator(UPDATE_SUCCESS));
    dispatch(loadInitData(searchParams));
    return res;
  } catch (error) {
    dispatch(actionCreator(UPDATE_ERROR, error.message));
  }
};

export const deleteCourse = (id) => async (dispatch, state) => {
  dispatch(actionCreator(DELETE));

  try {
    await CourseServices.delete(id);
    dispatch(actionCreator(DELETE_SUCCESS));
    dispatch(loadInitData());
  } catch (error) {
    dispatch(actionCreator(DELETE_ERROR, error.message));
  }
};

export const showCourse = (id) => async (dispatch, state) => {
  dispatch(actionCreator(GET));

  try {
    const res = await CourseServices.getByID(id);
    dispatch(actionCreator(GET_SUCCESS, res));
  } catch (error) {
    dispatch(actionCreator(GET_ERROR, error.message));
  }
};
