import { RegisterServices } from "services";

// ================ Action types ================ //
export const LOAD_REGISTRATIONS_REQUEST =
  "app/Regis/LOAD_REGISTRATIONS_REQUEST";
export const LOAD_REGISTRATIONS_SUCCESS =
  "app/Regis/LOAD_REGISTRATIONS_SUCCESS";
export const LOAD_REGISTRATIONS_ERROR = "app/Regis/LOAD_REGISTRATIONS_ERROR";

export const UPDATE_REGISTER_REQUEST = "app/Regis/UPDATE_REGISTER_REQUEST";
export const UPDATE_REGISTER_SUCCESS = "app/Regis/UPDATE_REGISTER_SUCCESS";
export const UPDATE_REGISTER_ERROR = "app/Regis/UPDATE_REGISTER_ERROR";

// ================ Reducer ================ //

const initialState = {
  registrations: [],
  meta: {},
  searchParams: { page: 1 },
  isRegisLoading: false,
  regisError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;
  switch (type) {
    case LOAD_REGISTRATIONS_ERROR:
      return {
        ...state,
        isRegisLoading: false,
        regisError: payload,
      };

    case LOAD_REGISTRATIONS_REQUEST:
      return {
        ...state,
        isRegisLoading: true,
        regisError: null,
      };

    case LOAD_REGISTRATIONS_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;
      return {
        ...state,
        isRegisLoading: false,
        registrations: data,
        meta: { ...rest },
        searchParams: { page },
      };

    case UPDATE_REGISTER_REQUEST:
      return {
        ...state,
        isRegisLoading: true,
        regisError: null,
      };

    case UPDATE_REGISTER_SUCCESS:
      return {
        ...state,
        isRegisLoading: false,
      };
    case UPDATE_REGISTER_ERROR:
      return {
        ...state,
        isRegisLoading: false,
        regisError: payload,
      };
    default:
      return state;
  }
}
// ================ Action creators ================ //
export const loadInitDataRequest = () => ({
  type: LOAD_REGISTRATIONS_REQUEST,
});

export const loadInitDataSuccess = (payload) => ({
  type: LOAD_REGISTRATIONS_SUCCESS,
  payload,
});

export const loadInitDataError = (error) => ({
  type: LOAD_REGISTRATIONS_ERROR,
  payload: error,
});

export const updateRegisterStatusRequest = () => ({
  type: UPDATE_REGISTER_REQUEST,
});

export const updateRegisterStatusSuccess = () => ({
  type: UPDATE_REGISTER_SUCCESS,
});

export const updateRegisterStatusError = (error) => ({
  type: UPDATE_REGISTER_ERROR,
  payload: error,
});
// Thunks

export const loadInitData = (params) => async (dispatch, getState) => {
  params = {
    ...params,
    include: 1,
  };
  dispatch(loadInitDataRequest());

  try {
    const data = await RegisterServices.getAll(params);
    dispatch(loadInitDataSuccess(data));
  } catch (err) {
    dispatch(loadInitDataError(err));
  }
};

export const updateRegisterStatus =
  (id, newStatus) => async (dispatch, getState) => {
    const searchParams = getState().regis.searchParams;

    dispatch(updateRegisterStatusRequest());
    try {
      await RegisterServices.update(id, {
        status: newStatus,
      });
      dispatch(updateRegisterStatusSuccess());
      dispatch(loadInitData(searchParams));
    } catch (error) {
      dispatch(updateRegisterStatusError(error.message));
    }
  };
