import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import * as appReducers from "../ducks";
import { isDev } from "configs/EnvironmentConfig";
const middlewares = [thunk];

// Reducers
const appReducer = combineReducers({ ...appReducers });

function configureStore(preloadedState) {
  // Enable Redux Devtools in client side dev mode.
  const composeEnhancers =
    isDev &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(
    appReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}

const store = configureStore();

export default store;
