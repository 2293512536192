import actionCreator from "redux/action";
import { CommentServices } from "services";

// ================ Action types ================ //
const LOAD_INIT_DATA = "app/comment/LOAD_INIT_DATA";
const LOAD_INIT_DATA_SUCCESS = "app/comment/LOAD_INIT_DATA_SUCCESS";
const LOAD_INIT_DATA_ERROR = "app/comment/LOAD_INIT_DATA_ERROR";

const ADD = "app/comment/ADD";
const ADD_SUCCESS = "app/comment/ADD_SUCCESS";
const ADD_ERROR = "app/comment/ADD_ERROR";

const UPDATE = "app/comment/UPDATE";
const UPDATE_SUCCESS = "app/comment/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/comment/UPDATE_ERROR";

const DELETE = "app/comment/DELETE";
const DELETE_SUCCESS = "app/comment/DELETE_SUCCESS";
const DELETE_ERROR = "app/comment/DELETE_ERROR";

// ================ Reducer ================ //

const initialState = {
  comments: [],
  meta: {},
  searchParams: { page: 1 },
  isCommentLoading: false,
  commentError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_INIT_DATA:
      return {
        ...state,
        isCommentLoading: true,
        commentError: null,
      };
    case LOAD_INIT_DATA_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;

      return {
        ...state,
        isCommentLoading: false,
        comments: data,
        meta: { ...rest },
        searchParams: { page },
      };
    case LOAD_INIT_DATA_ERROR:
      return {
        ...state,
        isCommentLoading: false,
        commentError: payload,
      };
    case ADD:
      return {
        ...state,
        isCommentLoading: true,
        commentError: null,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        isCommentLoading: false,
      };
    case ADD_ERROR:
      return {
        ...state,
        isCommentLoading: false,
        commentError: payload,
      };
    case DELETE:
      return {
        ...state,
        isCommentLoading: true,
        commentError: null,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        isCommentLoading: false,
      };
    case DELETE_ERROR:
      return {
        ...state,
        isCommentLoading: false,
        commentError: payload,
      };

    case UPDATE:
      return {
        ...state,
        isCommentLoading: true,
        commentError: null,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        commentError: false,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        isCommentLoading: false,
        commentError: payload,
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

// ================ Thunks ================ //
export const loadInitData = (params) => async (dispatch, state) => {
  dispatch(actionCreator(LOAD_INIT_DATA));
  try {
    const { courseId } = params;
    const courseType = 1;
    let response = await CommentServices.getAll(params);
    const comments = response && response.data;
    const courseComment = comments.filter(
      (comment) =>
        comment.commentFor === +courseId && comment.type === courseType
    );
    response = {
      ...response,
      data: courseComment,
    };
    dispatch(actionCreator(LOAD_INIT_DATA_SUCCESS, response));
  } catch (error) {
    dispatch(actionCreator(LOAD_INIT_DATA_ERROR, error.message));
  }
};

export const addComment = (record, param) => async (dispatch, state) => {
  dispatch(actionCreator(ADD));

  try {
    const response = await CommentServices.add(record);
    dispatch(actionCreator(ADD_SUCCESS));
    dispatch(loadInitData(param));
    return response;
  } catch (error) {
    dispatch(actionCreator(ADD_ERROR, error.message));
  }
};

export const updateComment =
  (id, updatedInfo, params) => async (dispatch, getState) => {
    const searchParams = getState().comment.searchParams;
    const loadDataParams = {
      ...params,
      ...searchParams,
    };
    dispatch(actionCreator(UPDATE));
    try {
      await CommentServices.update(id, updatedInfo);
      dispatch(actionCreator(UPDATE_SUCCESS));
      dispatch(loadInitData(loadDataParams));
    } catch (error) {
      dispatch(actionCreator(UPDATE_ERROR, error.message));
    }
  };

export const deleteComment = (id, params) => async (dispatch, getState) => {
  const searchParams = getState().comment.searchParams;
  const loadNewDataParams = {
    ...params,
    ...searchParams,
  };
  dispatch(actionCreator(DELETE));
  try {
    await CommentServices.delete(id);
    dispatch(actionCreator(DELETE_SUCCESS));
    dispatch(loadInitData(loadNewDataParams));
  } catch (error) {
    dispatch(actionCreator(DELETE_ERROR, error.message));
  }
};
