import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { notification } from "antd";
import { AUTH_TOKEN } from "redux/ducks/Auth.duck";
import TokenServices from "services/TokenServices";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "x-auth-token";
const PUBLIC_REQUEST_KEY = "public-request";
const REFRESH_TOKEN_INVALID = "Refresh token is not valid";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const token = TokenServices.getLocalToken();

    if (token) {
      config.headers[TOKEN_PAYLOAD_KEY] = token;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalConfig = error.config;

    if (originalConfig.url !== "/auth/login" && error.response) {
      // Access Token was expired
      if (
        error.response.data.msg === REFRESH_TOKEN_INVALID &&
        error.response.status === 401
      ) {
        TokenServices.removeUser();
        window.location.reload();
      }

      if (error.response.status === 401) {
        originalConfig._retry = true;
        try {
          const rs = await service.post("/auth/token", {
            refreshToken: TokenServices.getLocalRefreshToken(),
          });

          const { token } = rs;
          TokenServices.updateLocalToken(token);
          return service(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default service;
