import JwtAuthService from "services/JwtAuthService";
import TokenServices from "services/TokenServices";
import { updateUserInfo } from "./User.duck";
// ================ Action types ================ //
export const SIGNIN = "app/Auth/SIGNIN";
export const AUTHENTICATED = "app/Auth/AUTHENTICATED";
export const SIGNIN_ERROR = "app/Auth/SIGNIN_ERROR";

export const SIGNOUT_REQUEST = "app/Auth/SIGNOUT_REQUEST";
export const SIGNOUT_SUCCESS = "app/Auth/SIGNOUT_SUCCESS";
export const SIGNOUT_ERROR = "app/Auth/SIGNOUT_ERROR";

export const SHOW_AUTH_MESSAGE = "app/Auth/SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "app/Auth/HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "app/Auth/SHOW_LOADING";
export const AUTH_TOKEN = "auth_token";

export const LOAD_INIT_REQUEST = "app/Auth/LOAD_INIT_REQUEST";
export const LOAD_INIT_DATA_SUCCESS = "app/Auth/LOAD_INIT_DATA_SUCCESS";
// ================ Reducer ================ //
const isAuthenticated = TokenServices.getLocalToken()
  ? TokenServices.getLocalToken().length !== 0
  : false;
const initialState = {
  loading: false,
  isAuthenticated: isAuthenticated,
  signInError: null,
  message: "",
  showMessage: false,
  redirect: "",
  token: TokenServices.getLocalToken() || null,
  signoutInProgress: false,
  signoutError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.token,
        isAuthenticated: true,
        signInError: null,
      };
    case SIGNIN_ERROR: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        signInError: action.payload,
      };
    }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };

    case SIGNOUT_REQUEST: {
      return {
        ...state,
        signoutInProgress: true,
        signoutError: null,
        loading: true,
      };
    }

    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
        signoutInProgress: false,
        isAuthenticated: false,
      };
    }

    case SIGNOUT_ERROR: {
      return {
        ...state,
        signoutError: action.payload,
        redirect: "/",
        loading: false,
        signoutInProgress: false,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
        signInError: null,
      };
    }
    default:
      return state;
  }
}
// ================ Action creators ================ //

export const authenticated = (token) => ({
  type: AUTHENTICATED,
  token,
});
export const signInError = (error) => ({
  type: SIGNIN_ERROR,
  payload: error,
});

export const signoutSuccess = () => ({
  type: SIGNOUT_SUCCESS,
});
export const signoutRequest = () => ({
  type: SIGNOUT_REQUEST,
});
export const signoutError = (error) => ({
  type: SIGNOUT_ERROR,
  payload: error,
});

export const showAuthMessage = (message) => ({
  type: SHOW_AUTH_MESSAGE,
  message,
});
export const hideAuthMessage = () => ({
  type: HIDE_AUTH_MESSAGE,
});

export const showLoading = () => ({
  type: SHOW_LOADING,
});

// Selectors
export const authenticationInProgress = (state) => {
  const { signoutInProgress, loading } = state.auth;
  return signoutInProgress || loading;
};

// Thunks
const handleAuthMessage = (message, dispatch, timeout = 1500) => {
  dispatch(showAuthMessage(message));
  setTimeout(() => {
    dispatch(hideAuthMessage());
  }, timeout);
};

export const signIn = (user) => async (dispatch, getState) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error("Đăng nhập ..."));
  }

  dispatch(showLoading());

  try {
    const userData = await JwtAuthService.login(user);
    const { token } = userData;
    dispatch(authenticated(token));
    dispatch(updateUserInfo());
    TokenServices.setUser(userData);
    handleAuthMessage("Đăng nhập thành công", dispatch);
    return userData;
  } catch (err) {
    dispatch(signInError(err));
  }
};

export const signOut = () => async (dispatch, getState) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error("Đăng xuất ...."));
  }

  dispatch(signoutRequest());
  try {
    const response = await JwtAuthService.logOut({
      refreshToken: TokenServices.getLocalRefreshToken(),
    });

    TokenServices.removeUser();
    dispatch(signoutSuccess());
    dispatch(updateUserInfo());
    handleAuthMessage("Đăng xuất thành công", dispatch);
  } catch (err) {
    dispatch(signoutError(err));
    handleAuthMessage("Đăng xuất thất bại", dispatch);
  }
};
