const dev = {
  API_ENDPOINT_URL: "https://api.thevlanguage.edu.vn",
};

const prod = {
  API_ENDPOINT_URL: "https://api.thevlanguage.edu.vn",
};

const test = {
  API_ENDPOINT_URL: "https://api.thevlanguage.edu.vn",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const isDev = process.env.NODE_ENV === "development";
export const isProduction = process.env.NODE_ENV === "production";
export const env = getEnv();
