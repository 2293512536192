import actionCreator from "redux/action";
import { TestimonialServices } from "services";

// ================ Action types ================ //
const LOAD_INIT_DATA = "app/testimonial/LOAD_INIT_DATA";
const LOAD_INIT_DATA_SUCCESS = "app/testimonial/LOAD_INIT_DATA_SUCCESS";
const LOAD_INIT_DATA_ERROR = "app/testimonial/LOAD_INIT_DATA_ERROR";

const ADD = "app/testimonial/ADD";
const ADD_SUCCESS = "app/testimonial/ADD_SUCCESS";
const ADD_ERROR = "app/testimonial/ADD_ERROR";

const UPDATE = "app/testimonial/UPDATE";
const UPDATE_SUCCESS = "app/testimonial/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/testimonial/UPDATE_ERROR";

const DELETE = "app/testimonial/DELETE";
const DELETE_SUCCESS = "app/testimonial/DELETE_SUCCESS";
const DELETE_ERROR = "app/testimonial/DELETE_ERROR";

// ================ Reducer ================ //

const initialState = {
  testimonials: [],
  meta: {},
  searchParams: { page: 1 },
  isTestimonialLoading: false,
  testimonialError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_INIT_DATA:
      return {
        ...state,
        isTestimonialLoading: true,
        testimonialError: null,
      };
    case LOAD_INIT_DATA_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;

      return {
        ...state,
        isTestimonialLoading: false,
        testimonials: data,
        meta: { ...rest },
        searchParams: { page },
      };
    case LOAD_INIT_DATA_ERROR:
      return {
        ...state,
        isTestimonialLoading: false,
        testimonialError: payload,
      };
    case ADD:
      return {
        ...state,
        isTestimonialLoading: true,
        testimonialError: null,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        isTestimonialLoading: false,
      };
    case ADD_ERROR:
      return {
        ...state,
        isTestimonialLoading: false,
        testimonialError: payload,
      };
    case DELETE:
      return {
        ...state,
        isTestimonialLoading: true,
        testimonialError: null,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        isTestimonialLoading: false,
      };
    case DELETE_ERROR:
      return {
        ...state,
        isTestimonialLoading: false,
        testimonialError: payload,
      };

    case UPDATE:
      return {
        ...state,
        isTestimonialLoading: true,
        testimonialError: null,
      };
    case UPDATE_SUCCESS: {
      return {
        ...state,
        isTestimonialLoading: false,
      };
    }

    case UPDATE_ERROR:
      return {
        ...state,
        isTestimonialLoading: false,
        testimonialError: payload,
      };
    default:
      return state;
  }
}
// ================ Action creators ================ //

// ================ Thunks ================ //
export const loadInitData = (params) => async (dispatch, state) => {
  dispatch(actionCreator(LOAD_INIT_DATA));
  try {
    const response = await TestimonialServices.getAll(params);
    dispatch(actionCreator(LOAD_INIT_DATA_SUCCESS, response));
  } catch (error) {
    dispatch(actionCreator(LOAD_INIT_DATA_ERROR, error.message));
  }
};

export const addTestimonial = (record) => async (dispatch, state) => {
  dispatch(actionCreator(ADD));

  try {
    const response = await TestimonialServices.add(record);
    dispatch(actionCreator(ADD_SUCCESS));
    dispatch(loadInitData());
    return response;
  } catch (error) {
    dispatch(actionCreator(ADD_SUCCESS, error.message));
  }
};

export const updateTestimonial =
  (id, updatedInfo) => async (dispatch, getState) => {
    const searchParams = getState().testimonial.searchParams;

    dispatch(actionCreator(UPDATE));

    try {
      const response = await TestimonialServices.update(id, updatedInfo);
      dispatch(actionCreator(UPDATE_SUCCESS));
      dispatch(loadInitData(searchParams));
      return response;
    } catch (error) {
      dispatch(actionCreator(UPDATE_ERROR, error.message));
    }
  };

export const deleteTestimonial = (id) => async (dispatch, getState) => {
  const searchParams = getState().testimonial.searchParams;
  dispatch(actionCreator(DELETE));

  try {
    await TestimonialServices.delete(id);
    dispatch(actionCreator(DELETE_SUCCESS));
    dispatch(loadInitData(searchParams));
  } catch (error) {
    dispatch(actionCreator(DELETE_ERROR, error.message));
  }
};
