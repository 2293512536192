import React from "react";
import { APP_NAME } from "configs/AppConfig";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <a href="https://thevlanguage.com.vn" target="_blank">
          <span className="font-weight-semibold">{`${APP_NAME}`}</span>
        </a>{" "}
        All rights reserved.
      </span>
    </footer>
  );
}
