import actionCreator from "redux/action";
import { BannerServices } from "services";

// ================ Action types ================ //
const LOAD_INIT_DATA = "app/banner/LOAD_INIT_DATA";
const LOAD_INIT_DATA_SUCCESS = "app/banner/LOAD_INIT_DATA_SUCCESS";
const LOAD_INIT_DATA_ERROR = "app/banner/LOAD_INIT_DATA_ERROR";

const ADD = "app/banner/ADD";
const ADD_SUCCESS = "app/banner/ADD_SUCCESS";
const ADD_ERROR = "app/banner/ADD_ERROR";

const UPDATE = "app/banner/UPDATE";
const UPDATE_SUCCESS = "app/banner/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/banner/UPDATE_ERROR";

const DELETE = "app/banner/DELETE";
const DELETE_SUCCESS = "app/banner/DELETE_SUCCESS";
const DELETE_ERROR = "app/banner/DELETE_ERROR";

const MOVE_TOP_REQUEST = "app/banner/MOVE_TOP";
const MOVE_TOP_SUCCESS = "app/banner/MOVE_TOP_SUCCESS";
const MOVE_TOP_ERROR = "app/banner/MOVE_TOP_ERROR";

const UPDATE_ORDER_REQUEST = "app/banner/UPDATE_ORDER_REQUEST";
const UPDATE_ORDER_SUCCESS = "app/banner/ UPDATE_ORDER_SUCCESS ";
const UPDATE_ORDER_ERROR = "app/banner/UPDATE_ORDER_ERROR";
// ================ Reducer ================ //

const initialState = {
  banners: [],
  meta: {},
  searchParams: { page: 1 },
  loadInitDataInProgress: false,
  loadInitDataError: null,

  addBannerInProgress: false,
  addBannerError: null,

  updateBannerInProgress: false,
  updateBannerError: null,

  deleteInProgress: false,
  deleteError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_INIT_DATA:
      return {
        ...state,
        loadInitDataInProgress: true,
        loadInitDataError: null,
      };
    case LOAD_INIT_DATA_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;
      return {
        ...state,
        loadInitDataInProgress: false,
        banners: data,
        meta: { ...rest },
        searchParams: { page },
      };
    case LOAD_INIT_DATA_ERROR:
      return {
        ...state,
        loadInitDataInProgress: false,
        loadInitDataError: payload,
      };
    case ADD:
      return {
        ...state,
        addInProgress: true,
        addError: null,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        addInProgress: false,
      };
    case ADD_ERROR:
      return {
        ...state,
        addInProgress: false,
        addError: payload,
      };
    case DELETE:
      return {
        ...state,
        deleteInProgress: true,
        deleteError: null,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        deleteInProgress: false,
      };
    case DELETE_ERROR:
      return {
        ...state,
        deleteInProgress: false,
        deleteError: payload,
      };

    case UPDATE:
      return {
        ...state,
        updateInProgress: true,
        updateError: null,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        updateInProgress: false,
        updateError: payload,
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

// ================ Thunks ================ //
export const loadInitData = (params) => async (dispatch, state) => {
  dispatch(actionCreator(LOAD_INIT_DATA));
  try {
    const banners = await BannerServices.getAll(params);
    dispatch(actionCreator(LOAD_INIT_DATA_SUCCESS, banners));
  } catch (error) {
    dispatch(actionCreator(LOAD_INIT_DATA_ERROR, error.message));
  }
};

export const addBanner = (record) => async (dispatch, state) => {
  dispatch(actionCreator(ADD));

  try {
    const banner = await BannerServices.add(record);
    dispatch(actionCreator(ADD_SUCCESS));
    dispatch(loadInitData());
    return banner;
  } catch (error) {
    dispatch(actionCreator(ADD_ERROR, error.message));
  }
};

export const updateBanner = (id, updatedInfo) => async (dispatch, getState) => {
  const searchParams = getState().banner.searchParams;
  dispatch(actionCreator(UPDATE));
  try {
    await BannerServices.update(id, updatedInfo);
    dispatch(actionCreator(UPDATE_SUCCESS));
    dispatch(loadInitData(searchParams));
  } catch (error) {
    dispatch(actionCreator(UPDATE_ERROR, error.message));
  }
};

export const deleteBanner = (id) => async (dispatch, getState) => {
  const searchParams = getState().banner.searchParams;
  dispatch(actionCreator(DELETE));
  try {
    await BannerServices.delete(id);
    dispatch(actionCreator(DELETE_SUCCESS));
    dispatch(loadInitData(searchParams));
  } catch (error) {
    dispatch(actionCreator(DELETE_ERROR, error.message));
  }
};

export const moveTop = (id) => async (dispatch, getState) => {
  const searchParams = getState().banner.searchParams;
  dispatch(actionCreator(MOVE_TOP_REQUEST));
  try {
    await BannerServices.moveTop(id);
    dispatch(actionCreator(MOVE_TOP_SUCCESS));
    dispatch(loadInitData(searchParams));
  } catch (error) {
    dispatch(actionCreator(MOVE_TOP_ERROR, error.message));
  }
};

export const updateOrder = (newUpdateArr) => async (dispatch, getState) => {
  const searchParams = getState().banner.searchParams;
  dispatch(actionCreator(UPDATE_ORDER_REQUEST));
  try {
    await BannerServices.updateOrder(newUpdateArr);
    dispatch(actionCreator(UPDATE_ORDER_SUCCESS));
    dispatch(loadInitData(searchParams));
  } catch (error) {
    dispatch(actionCreator(UPDATE_ORDER_ERROR, error.message));
  }
};
