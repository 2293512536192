import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import { EditOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/ducks/Auth.duck";

/**
 * Uncomment if you want to add more menu item
 */
const menuItem = [
  // {
  //   title: "Edit Profile",
  //   icon: EditOutlined,
  //   path: "/",
  // },
];

export const NavProfile = (props) => {
  const { onSignout, fullName, username } = props;
  const ava = "/img/logo-ava.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <img
            src={ava}
            alt="The V Language logo avatar"
            width={45}
            height={45}
          />
          <div className="pl-3">
            <h4 className="mb-0">{fullName}</h4>
            <span className="text-muted">Admin</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })} */}
          <Menu.Item key={menuItem.length + 1} onClick={(e) => onSignout()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar size={45} icon={<UserOutlined />} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  const { user } = state.user;
  const { firstName, lastName, username } = user;

  return {
    fullName: firstName + " " + lastName,
    username: username,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onSignout: () => dispatch(signOut()),
});
export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
