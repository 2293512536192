import { THEME_CONFIG } from "configs/AppConfig";
// ================ Action types ================ //
export const TOGGLE_COLLAPSED_NAV = "app/Theme/TOGGLE_COLLAPSED_NAV";
export const CHANGE_LOCALE = "app/Theme/CHANGE_LOCALE";
export const SIDE_NAV_STYLE_CHANGE = "app/Theme/SIDE_NAV_STYLE_CHANGE";
export const NAV_TYPE_CHANGE = "app/Theme/NAV_TYPE_CHANGE";
export const TOP_NAV_COLOR_CHANGE = "app/Theme/TOP_NAV_COLOR_CHANGE";
export const HEADER_NAV_COLOR_CHANGE = "HEADER_NAV_COLOR_CHANGE";
export const TOGGLE_MOBILE_NAV = "app/Theme/TOGGLE_MOBILE_NAV";
export const SWITCH_THEME = "app/Theme/SWITCH_THEME";
export const DIRECTION_CHANGE = "app/Theme/DIRECTION_CHANGE";

// ================ Reducer ================ //
const initTheme = {
  ...THEME_CONFIG,
};

export default function reducer(state = initTheme, action = {}) {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case SIDE_NAV_STYLE_CHANGE:
      return {
        ...state,
        sideNavTheme: action.sideNavTheme,
      };
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case NAV_TYPE_CHANGE:
      return {
        ...state,
        navType: action.navType,
      };
    case TOP_NAV_COLOR_CHANGE:
      return {
        ...state,
        topNavColor: action.topNavColor,
      };
    case HEADER_NAV_COLOR_CHANGE:
      return {
        ...state,
        headerNavColor: action.headerNavColor,
      };
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav,
      };
    case SWITCH_THEME:
      return {
        ...state,
        currentTheme: action.currentTheme,
      };
    case DIRECTION_CHANGE:
      return {
        ...state,
        direction: action.direction,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export function toggleCollapsedNav(navCollapsed) {
  return {
    type: TOGGLE_COLLAPSED_NAV,
    navCollapsed,
  };
}

export function onNavStyleChange(sideNavTheme) {
  return {
    type: SIDE_NAV_STYLE_CHANGE,
    sideNavTheme,
  };
}

export function onLocaleChange(locale) {
  return {
    type: CHANGE_LOCALE,
    locale,
  };
}

export function onNavTypeChange(navType) {
  return {
    type: NAV_TYPE_CHANGE,
    navType,
  };
}

export function onTopNavColorChange(topNavColor) {
  return {
    type: TOP_NAV_COLOR_CHANGE,
    topNavColor,
  };
}

export function onHeaderNavColorChange(headerNavColor) {
  return {
    type: HEADER_NAV_COLOR_CHANGE,
    headerNavColor,
  };
}

export function onMobileNavToggle(mobileNav) {
  return {
    type: TOGGLE_MOBILE_NAV,
    mobileNav,
  };
}

export function onSwitchTheme(currentTheme) {
  return {
    type: SWITCH_THEME,
    currentTheme,
  };
}

export function onDirectionChange(direction) {
  return {
    type: DIRECTION_CHANGE,
    direction,
  };
}
