import fetch from "auth/FetchInterceptor";

class GAServicesClass {
  _baseUrl;

  constructor(baseUrl) {
    this._baseUrl = `/${baseUrl}`;
  }

  _createUrlById(id) {
    return `${this._baseUrl}/${id}`;
  }

  getByMetric(subUrl, params) {
    const url = `${this._baseUrl}/${subUrl}`;
    return fetch({
      url,
      params,
    });
  }
}
export default GAServicesClass;
