import actionCreator from "redux/action";
import { ScheduleServices } from "services";

// ================ Action types ================ //
const LOAD_INIT_DATA = "app/schedule/LOAD_INIT_DATA";
const LOAD_INIT_DATA_SUCCESS = "app/schedule/LOAD_INIT_DATA_SUCCESS";
const LOAD_INIT_DATA_ERROR = "app/schedule/LOAD_INIT_DATA_ERROR";

const ADD = "app/schedule/ADD";
const ADD_SUCCESS = "app/schedule/ADD_SUCCESS";
const ADD_ERROR = "app/schedule/ADD_ERROR";

const UPDATE = "app/schedule/UPDATE";
const UPDATE_SUCCESS = "app/schedule/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/schedule/UPDATE_ERROR";

const DELETE = "app/schedule/DELETE";
const DELETE_SUCCESS = "app/schedule/DELETE_SUCCESS";
const DELETE_ERROR = "app/schedule/DELETE_ERROR";

// ================ Reducer ================ //

const initialState = {
  schedules: [],
  meta: {},
  searchParams: { page: 1 },
  isScheduleLoading: false,
  scheduleError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_INIT_DATA:
      return {
        ...state,
        isScheduleLoading: true,
        scheduleError: null,
      };
    case LOAD_INIT_DATA_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;

      return {
        ...state,
        isScheduleLoading: false,
        schedules: data,
        meta: { ...rest },
        searchParams: { page },
      };
    case LOAD_INIT_DATA_ERROR:
      return {
        ...state,
        isScheduleLoading: false,
        scheduleError: payload,
      };
    case ADD:
      return {
        ...state,
        isScheduleLoading: true,
        scheduleError: null,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        isScheduleLoading: false,
      };
    case ADD_ERROR:
      return {
        ...state,
        isScheduleLoading: false,
        scheduleError: payload,
      };
    case DELETE:
      return {
        ...state,
        isScheduleLoading: true,
        scheduleError: null,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        isScheduleLoading: false,
      };
    case DELETE_ERROR:
      return {
        ...state,
        isScheduleLoading: false,
        scheduleError: payload,
      };

    case UPDATE:
      return {
        ...state,
        isScheduleLoading: true,
        scheduleError: null,
      };
    case UPDATE_SUCCESS: {
      return {
        ...state,
        isScheduleLoading: false,
      };
    }

    case UPDATE_ERROR:
      return {
        ...state,
        isScheduleLoading: false,
        scheduleError: payload,
      };
    default:
      return state;
  }
}
// ================ Action creators ================ //

// ================ Thunks ================ //
export const loadInitData = (params) => async (dispatch, state) => {
  dispatch(actionCreator(LOAD_INIT_DATA));

  try {
    const response = await ScheduleServices.getAll(params);
    dispatch(actionCreator(LOAD_INIT_DATA_SUCCESS, response));
  } catch (error) {
    dispatch(actionCreator(LOAD_INIT_DATA_ERROR, error.message));
  }
};

export const addSchedule = (record, param) => async (dispatch, state) => {
  dispatch(actionCreator(ADD));

  try {
    const response = await ScheduleServices.add(record);
    dispatch(actionCreator(ADD_SUCCESS));
    dispatch(loadInitData(param));
    return response;
  } catch (error) {
    dispatch(actionCreator(ADD_SUCCESS, error.message));
  }
};

export const updateSchedule =
  (id, updatedInfo, params) => async (dispatch, getState) => {
    const searchParams = getState().testimonial.searchParams;
    const loadDataParams = {
      ...params,
      ...searchParams,
    };
    dispatch(actionCreator(UPDATE));

    try {
      const response = await ScheduleServices.update(id, updatedInfo);
      dispatch(actionCreator(UPDATE_SUCCESS));
      dispatch(loadInitData(loadDataParams));
      return response;
    } catch (error) {
      dispatch(actionCreator(UPDATE_ERROR, error.message));
    }
  };

export const deleteSchedule = (id, params) => async (dispatch, getState) => {
  const searchParams = getState().testimonial.searchParams;
  dispatch(actionCreator(DELETE));
  const loadNewDataParams = {
    ...params,
    ...searchParams,
  };
  try {
    await ScheduleServices.delete(id);
    dispatch(actionCreator(DELETE_SUCCESS));

    dispatch(loadInitData(loadNewDataParams));
  } catch (error) {
    dispatch(actionCreator(DELETE_ERROR, error.message));
  }
};
