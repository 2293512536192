import BaseServices from "./BaseServices.js";
import GAServicesClass from "./GAServices";

export const BannerServices = new BaseServices("banners");
export const ContactServices = new BaseServices("contacts");
export const RegisterServices = new BaseServices("registrations");
export const TeacherServices = new BaseServices("teachers");
export const TestimonialServices = new BaseServices("testimonials");
export const IntroServices = new BaseServices("intro");
export const CategoryServices = new BaseServices("categories");
export const CourseServices = new BaseServices("courses");
export const NewServices = new BaseServices("news");
export const ActivityServices = new BaseServices("activities");
export const ScheduleServices = new BaseServices("course-schedules");
export const TestResultServices = new BaseServices("test-results");
export const ReviewServices = new BaseServices("testomonials");
export const CommentServices = new BaseServices("comments");
export const GAServices = new GAServicesClass("stats");
