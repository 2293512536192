import fetch from "auth/FetchInterceptor";

const login = function (data) {
  return fetch({
    url: "/auth/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

const signUp = function (data) {
  return fetch({
    url: "/auth/signup",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

const getUserInfoFromToken = function () {
  return fetch({
    url: "/auth",
    method: "get",
  });
};

const logOut = (record) => {
  return fetch({
    url: "/auth/logout",
    method: "post",
    data: record,
  });
};
const JwtAuthService = {
  login,
  signUp,
  getUserInfoFromToken,
  logOut,
};

export default JwtAuthService;
