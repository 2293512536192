import moment from "moment";
import actionCreator from "redux/action";
import { GAServices } from "services";
const initialState = {
  isDashboardLoading: false,
  data: {
    visitorData: {
      series: [],
      categories: [],
    },
    activeUser: {
      title: "",
      value: 0,
      status: 0,
      subtitle: "",
    },
    pageView: {
      title: "",
      value: 0,
      status: 0,
      subtitle: "",
    },
    pageViewSession: {
      title: "",
      value: 0,
      status: 0,
      subtitle: "",
    },
    newUsers: {
      title: "",
      value: 0,
      status: 0,
      subtitle: "",
    },
    sessionByChanels: {
      sessionData: [],
      sessionLabels: [],
    },
    pageViewByPage: [],
  },
  dashboardError: null,
};

const LOAD_DATA_REQUEST = "app/dashboard/LOAD_DATA_REQUEST";
const LOAD_DATA_SUCCESS = "app/dashboard/LOAD_DATA_SUCCESS";
const LOAD_DATA_ERROR = "app/dashboard/LOAD_DATA_ERROR";

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_DATA_REQUEST:
      return {
        ...state,
        isDashboardLoading: true,
        dashboardError: null,
      };
    case LOAD_DATA_SUCCESS:
      return {
        ...state,
        isDashboardLoading: false,
        data: payload,
      };
    case LOAD_DATA_ERROR:
      return {
        isDashboardLoading: false,
        dashboardError: payload,
      };

    default:
      return state;
  }
}

// Thunks

export const loadInitData =
  (params = {}) =>
  async (dispatch, getState) => {
    const promises = [
      GAServices.getByMetric("active-users", params),
      GAServices.getByMetric("new-users", params),
      GAServices.getByMetric("page-views", params),
      GAServices.getByMetric("page-view-per-session", params),
      GAServices.getByMetric("page-views-by-pages", params),
      GAServices.getByMetric("sessions-by-channels", params),
    ];
    const results = await Promise.all(promises.map((p) => p.catch((e) => e)));
    const validResults = await results.filter(
      (result) => !(result instanceof Error)
    );
    const normalizeResults = validResults.map((result) => {
      return result.data;
    });
    const activeUsers = normalizeTimeSeriesData(
      normalizeResults[0],
      "Active users"
    );
    const newUsers = normalizeTimeSeriesData(normalizeResults[1], "New users");
    const pageView = normalizeTimeSeriesData(normalizeResults[2], "Page views");
    const pageViewBySession = normalizeTimeSeriesData(
      normalizeResults[3],
      "Page views per session"
    );
    const sessionByChanelsData = normalizeResults[5];
    let sessionData = [];
    let sessionLabels = [];
    const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
    const getLabelBykey = (key) => {
      return key
        .split("_")
        .map((char) => capitalize(char))
        .join(" ");
    };

    for (let record of sessionByChanelsData) {
      const key = Object.keys(record) && Object.keys(record)[0];
      sessionData = [...sessionData, record[key]];
      sessionLabels = [...sessionLabels, getLabelBykey(key)];
    }

    const visitorData = mergeVisitorData([
      activeUsers,
      pageView,
      pageViewBySession,
      newUsers,
    ]);

    const data = {
      visitorData,
      activeUser: countMetric(activeUsers, "Active users"),
      pageView: countMetric(pageView, "Page views"),
      pageViewSession: countMetric(pageViewBySession, "Page views by session"),
      newUsers: countMetric(newUsers, "New users"),
      sessionByChanels: {
        sessionData,
        sessionLabels,
      },
      pageViewByPage: normalizeResults[4] || [],
    };
    dispatch(actionCreator(LOAD_DATA_SUCCESS, data));
  };

export const normalizeTimeSeriesData = (data, metricLabel) => {
  if (!Array.isArray(data)) {
    return null;
  }
  const seriesValue = data.map((record) => record.value);
  const seriesLabel = data.map((record) =>
    moment(record.date, "DD/MM/YYYY").format("DD MMM").toString()
  );

  return {
    series: [
      {
        name: metricLabel,
        data: seriesValue,
      },
    ],
    categories: seriesLabel,
  };
};

export const mergeVisitorData = (metricList) => {
  let series = [];
  for (let item of metricList) {
    series = [...series, item.series[0]];
  }
  return {
    series,
    categories: metricList[0].categories,
  };
};

export const countMetric = (metric, labelName) => {
  const values = metric?.series[0].data || [];
  const total = values.reduce((partialSum, a) => partialSum + a, 0);
  let rate = 0;
  if (values.length <= 1) {
    rate = 0;
  } else {
    rate = (values[values.length - 1] / values[values.length - 2]).toFixed(2);
  }
  return {
    value: total,
    status: +rate >= 1 ? rate * 1 : rate * -1,
    title: labelName,
    subtitle: `Compare to yesterday`,
  };
};
