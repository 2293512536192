import actionCreator from "redux/action";
import { NewServices } from "services";

// ================ Action types ================ //
const LOAD_INIT_DATA = "app/new/LOAD_INIT_DATA";
const LOAD_INIT_DATA_SUCCESS = "app/new/LOAD_INIT_DATA_SUCCESS";
const LOAD_INIT_DATA_ERROR = "app/new/LOAD_INIT_DATA_ERROR";

const ADD = "app/new/ADD";
const ADD_SUCCESS = "app/new/ADD_SUCCESS";
const ADD_ERROR = "app/new/ADD_ERROR";

const UPDATE = "app/new/UPDATE";
const UPDATE_SUCCESS = "app/new/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/new/UPDATE_ERROR";

const DELETE = "app/new/DELETE";
const DELETE_SUCCESS = "app/new/DELETE_SUCCESS";
const DELETE_ERROR = "app/new/DELETE_ERROR";
// ================ Reducer ================ //

const initialState = {
  news: [],
  meta: {},
  searchParams: { page: 1 },
  isNewLoading: false,
  newError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_INIT_DATA:
      return {
        ...state,
        isNewLoading: true,
        newError: null,
      };
    case LOAD_INIT_DATA_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;

      return {
        ...state,
        isNewLoading: false,
        news: data,
        meta: { ...rest },
        searchParams: { page },
      };
    case LOAD_INIT_DATA_ERROR:
      return {
        ...state,
        isNewLoading: false,
        newError: payload,
      };
    case ADD:
      return {
        ...state,
        isNewLoading: true,
        newError: null,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        isNewLoading: false,
      };
    case ADD_ERROR:
      return {
        ...state,
        isNewLoading: false,
        newError: payload,
      };
    case DELETE:
      return {
        ...state,
        isNewLoading: true,
        newError: null,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        isNewLoading: false,
      };
    case DELETE_ERROR:
      return {
        ...state,
        isNewLoading: false,
        newError: payload,
      };

    case UPDATE:
      return {
        ...state,
        isNewLoading: true,
        newError: null,
      };
    case UPDATE_SUCCESS: {
      return {
        ...state,
        isNewLoading: false,
      };
    }

    case UPDATE_ERROR:
      return {
        ...state,
        isNewLoading: false,
        newError: payload,
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

// ================ Thunks ================ //
export const loadInitData = (params) => async (dispatch, state) => {
  dispatch(actionCreator(LOAD_INIT_DATA));
  try {
    const news = await NewServices.getAll(params);
    dispatch(actionCreator(LOAD_INIT_DATA_SUCCESS, news));
  } catch (error) {
    dispatch(actionCreator(LOAD_INIT_DATA_ERROR, error.message));
  }
};

export const addNew = (news) => async (dispatch, state) => {
  dispatch(actionCreator(ADD));
  try {
    const res = await NewServices.add(news);
    dispatch(actionCreator(ADD_SUCCESS));
    dispatch(loadInitData());
    return res;
  } catch (error) {
    dispatch(actionCreator(ADD_SUCCESS, error.message));
  }
};

export const updateNew = (id, updatedInfo) => async (dispatch, getState) => {
  dispatch(actionCreator(UPDATE));
  try {
    const res = await NewServices.update(id, updatedInfo);
    dispatch(actionCreator(UPDATE_SUCCESS));
    dispatch(loadInitData());
    return res;
  } catch (error) {
    dispatch(actionCreator(UPDATE_ERROR, error.message));
  }
};

export const deleteNew = (id) => async (dispatch, state) => {
  dispatch(actionCreator(DELETE));

  try {
    await NewServices.delete(id);
    dispatch(actionCreator(DELETE_SUCCESS));
    dispatch(loadInitData());
  } catch (error) {
    dispatch(actionCreator(DELETE_ERROR, error.message));
  }
};
