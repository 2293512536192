import actionCreator from "redux/action";
import { CategoryServices } from "services";

// ================ Action types ================ //
const LOAD_INIT_DATA = "app/category/LOAD_INIT_DATA";
const LOAD_INIT_DATA_SUCCESS = "app/category/LOAD_INIT_DATA_SUCCESS";
const LOAD_INIT_DATA_ERROR = "app/category/LOAD_INIT_DATA_ERROR";

const ADD = "app/category/ADD";
const ADD_SUCCESS = "app/category/ADD_SUCCESS";
const ADD_ERROR = "app/category/ADD_ERROR";

const UPDATE = "app/category/UPDATE";
const UPDATE_SUCCESS = "app/category/UPDATE_SUCCESS";
const UPDATE_ERROR = "app/category/UPDATE_ERROR";

const DELETE = "app/category/DELETE";
const DELETE_SUCCESS = "app/category/DELETE_SUCCESS";
const DELETE_ERROR = "app/category/DELETE_ERROR";

// ================ Reducer ================ //

const initialState = {
  categories: [],
  meta: {},
  searchParams: { page: 1 },
  loadInitDataInProgress: false,
  loadInitDataError: null,

  addCategoryInProgress: false,
  addCategoryError: null,

  updateCategoryInProgress: false,
  updateCategoryError: null,

  deleteInProgress: false,
  deleteError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_INIT_DATA:
      return {
        ...state,
        loadInitDataInProgress: true,
        loadInitDataError: null,
      };
    case LOAD_INIT_DATA_SUCCESS:
      const { data, ...rest } = payload;
      const { page } = rest;
      return {
        ...state,
        loadInitDataInProgress: false,
        categories: data,
        meta: { ...rest },
        searchParams: { page },
      };
    case LOAD_INIT_DATA_ERROR:
      return {
        ...state,
        loadInitDataInProgress: false,
        loadInitDataError: payload,
      };
    case ADD:
      return {
        ...state,
        addInProgress: true,
        addError: null,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        addInProgress: false,
      };
    case ADD_ERROR:
      return {
        ...state,
        addInProgress: false,
        addError: payload,
      };
    case DELETE:
      return {
        ...state,
        deleteInProgress: true,
        deleteError: null,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        deleteInProgress: false,
      };
    case DELETE_ERROR:
      return {
        ...state,
        deleteInProgress: false,
        deleteError: payload,
      };

    case UPDATE:
      return {
        ...state,
        updateInProgress: true,
        updateError: null,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        updateInProgress: false,
        updateError: payload,
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

// ================ Thunks ================ //
export const loadInitData = (params) => async (dispatch, state) => {
  dispatch(actionCreator(LOAD_INIT_DATA));
  try {
    const categories = await CategoryServices.getAll(params);
    dispatch(actionCreator(LOAD_INIT_DATA_SUCCESS, categories));
  } catch (error) {
    dispatch(actionCreator(LOAD_INIT_DATA_ERROR, error.message));
  }
};

export const addCategory = (record) => async (dispatch, state) => {
  dispatch(actionCreator(ADD));

  try {
    const category = await CategoryServices.add(record);
    dispatch(actionCreator(ADD_SUCCESS));
    dispatch(loadInitData());
    return category;
  } catch (error) {
    dispatch(actionCreator(ADD_ERROR, error.message));
  }
};

export const updateCategory =
  (id, updatedInfo) => async (dispatch, getState) => {
    const searchParams = getState().categories.searchParams;
    dispatch(actionCreator(UPDATE));
    try {
      await CategoryServices.update(id, updatedInfo);
      dispatch(actionCreator(UPDATE_SUCCESS));
      dispatch(loadInitData(searchParams));
    } catch (error) {
      dispatch(actionCreator(UPDATE_ERROR, error.message));
    }
  };

export const deleteCategory = (id) => async (dispatch, getState) => {
  const searchParams = getState().categories.searchParams;
  dispatch(actionCreator(DELETE));
  try {
    await CategoryServices.delete(id);
    dispatch(actionCreator(DELETE_SUCCESS));
    dispatch(loadInitData(searchParams));
  } catch (error) {
    dispatch(actionCreator(DELETE_ERROR, error.message));
  }
};
