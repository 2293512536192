import fetch from "auth/FetchInterceptor";
class BaseServices {
  _baseUrl;

  constructor(baseUrl) {
    this._baseUrl = `/${baseUrl}`;
  }

  _createUrlById(id) {
    return `${this._baseUrl}/${id}`;
  }

  getAll(params) {
    return fetch({
      url: this._baseUrl,
      method: "GET",
      params,
    });
  }
  getById(id) {
    const url = this._createUrlById(id);
    return fetch({
      url,
      method: "GET",
    });
  }
  add(record) {
    return fetch({
      url: this._baseUrl,
      method: "POST",
      data: record,
    });
  }
  update(id, updateRecord) {
    const url = this._createUrlById(id);

    return fetch({
      url,
      method: "PUT",
      data: updateRecord,
    });
  }
  delete(id) {
    const url = this._createUrlById(id);
    return fetch({
      url,
      method: "DELETE",
    });
  }
  moveTop(id) {
    const url = `${this._baseUrl}/move-top/${id}`;
    return fetch({
      url,
      method: "PUT",
    });
  }

  updateOrder(updareOrderArr) {
    const url = `${this._baseUrl}/move`;
    return fetch({
      url,
      method: "PUT",
      data: updareOrderArr,
    });
  }
}

export default BaseServices;
