import auth from "./Auth.duck";
import theme from "./Theme.duck";
import regis from "./Regis.duck";
import contact from "./Contact.duck";
import user from "./User.duck";
import teacher from "./Teacher.duck";
import banner from "./Banner.duck";
import testimonial from "./Testomonial.duck";
import intro from "./Intro.duck";
import categories from "./Category.duck";
import course from "./Course.duck";
import news from "./New.duck";
import activity from "./Activity.duck";
import tests from "./TestResult.duck";
import schedules from "./Schedule.duck";
import comment from "./Comment.duck";
import commentTeacher from "./CommentTeacher.duck";
import dashboard from "./Dashboard.duck";
export {
  auth,
  theme,
  regis,
  user,
  contact,
  course,
  teacher,
  testimonial,
  banner,
  intro,
  categories,
  news,
  activity,
  tests,
  schedules,
  comment,
  commentTeacher,
  dashboard,
};
